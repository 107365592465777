import React from "react";
import { Link, NavLink } from "react-router-dom";
export default function Header(){
return(
<>
<div className="snow-ball-container">
<div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    <div className="snow"></div>
    </div>
{/* <!-- navbar section starts  --> */}
<nav className="navbar">
   <Link to="/">
   <i className="fas fa-home"></i> <span>home</span> </Link>
   <Link to="/about">
   <i className="fas fa-user"></i> <span>about</span> </Link>
   <Link to="/experience">
   <i className="fas fa-blog"></i> <span>Experience</span> </Link>
   <Link to="/projects">
   <i className="fas fa-briefcase"></i> <span>Projects</span> </Link>
   <Link to="/contact">
   <i className="fas fa-address-book"></i> <span>contact</span> </Link>
</nav>
{/* <!-- navbar section ends -->
<!-- mobile navbar section start--> */}
<nav id="mobile-navbar">
   <ul>
      <li>
         <NavLink to="/" className="myHomeA">
            <i className="lni lni-home"></i>
         </NavLink>
      </li>
   </ul>
   <ul>
      <li>
         <NavLink to="/about" className="myAboutA">
            <i className="lni lni-user"></i>
         </NavLink>
      </li>
   </ul>
   <ul>
      <li>
         <NavLink to="/projects" className="myProjectA">
            <i className="lni lni-book"></i>
         </NavLink>
      </li>
   </ul>
   <ul>
      <li>
         <NavLink to="/experience" className="myExpA">
            <i className="lni lni-apartment"></i>
         </NavLink>
      </li>
   </ul>
   <ul>
      <li>
         <NavLink to="/contact" className="myContactA">
            <i className="lni lni-phone"></i>
         </NavLink>
      </li>
   </ul>
   <div className="tubelight">
      <div className="light-ray"></div>
   </div>
</nav>
{/* <!-- mobile navbar section ends--> */}
</>
);
}
// export default Header;