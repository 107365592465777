import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './Components/Header';
import Footer from './Components/Footer';
// import Home from './Components/Home';
// import About from './Components/About'
// import Experience from "./Components/Experience";
// import Project from "./Components/Project"
// import Contact from "./Components/Contact";
import { lazy, Suspense } from "react";

//const Header = lazy(() => wait(1000).then(() => import('./Components/Header')))
//const Footer = lazy(() => wait(1000).then(() => import('./Components/Footer')))
const Home = lazy(() => wait(1000).then(() => import('./Components/Home')))
const About = lazy(() => wait(1000).then(() => import('./Components/About')))
const Experience = lazy(() => wait(1000).then(() => import('./Components/Experience')))
const Project = lazy(() => wait(1000).then(() => import('./Components/Project')))
const Contact = lazy(() => wait(1000).then(() => import('./Components/Contact')))
const Loder = lazy(() => wait(1000).then(() => import('./Components/Loader')))


function App() {
  return (
    <>
    
    <BrowserRouter>
    <Header />
    <Suspense fallback={<Loder />}>
    <Routes>
        <Route exact path="/">
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="experience" element={<Experience />} />
          <Route path="projects" element={<Project />} />
          <Route path="contact" element={<Contact />} />
        </Route>
      </Routes>
      </Suspense>
      <Footer />
    </BrowserRouter>
    </>
  );
}

function wait(time){
  return new Promise(resolve => {
    setTimeout(resolve, time)
  })
}

export default App;
